/*
    Popover styling
*/

.popover {
    padding: 0 !important;
}

.popover-title-primary {
    background-color: @brand-primary;
    color: @white;
}

.popover-title-success {
    background-color: @brand-success;
    color: @white;
}

.popover-title-info {
    background-color: @brand-info;
    color: @white;
}

.popover-title-warning {
    background-color: @brand-warning;
    color: @white;
}

.popover-title-danger {
    background-color: @brand-danger;
    color: @white;
}