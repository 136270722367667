form.signup-form {
  .nav-tabs {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(17, 143, 221, 0.24);
  }
  .recaptcha-container {
    text-align: center;
    margin-bottom: 15px;
    .recaptcha-element {
      display: inline-block;
    }
  }
}
