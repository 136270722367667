@import "~bootstrap/less/variables.less";
@import "~font-awesome/less/font-awesome.less";
@import "~react-datetime/css/react-datetime.css";

@import "dropzone.less";

@import "variables.less";
@import "mixins.less";
@import "theme_mixins.less";

//For elements used in Proctor theme
@import "theme.less";

//For elements customized from Bootstrap
@import "type.less";
@import "tables.less";
@import "dropdowns.less";
@import "list-group.less";
@import "nav.less";
@import "labels.less";
@import "alerts.less";
@import "badges.less";
@import "progress-bars.less";
@import "panels.less";
@import "buttons.less";
@import "forms.less";
@import "breadcrumbs.less";
@import "pager.less";
@import "pagination.less";
@import "modals.less";
@import "popovers.less";
@import "plugins.less";

@import "pages/index";

.ttt {
  background: url('../favicon.png');
}

a, .table-hover tr {
  cursor: pointer;
}

h1 {
  font-size: 32px;
  line-height: 32px;
}

h3 {
  font-size: 16px;
  line-height: 23px;
  margin-top: 22px;

  & small {
    font-size: 14px;
    color: #444444;
  }
}

.file {
  font-size: 1.2em;
  line-height: 2em;
  padding: 0.4em;
  margin-bottom: 0.4em;

}

.files {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 0.4em;
  }
}

.logo {
  padding-top: 0;
  margin-left: 0.5em;
}

dd {
  margin-bottom: 0.5em;
}

.navbar-default .navbar-toggle .icon-bar {
  background: #888;
}

@media (max-width: @screen-xs-max) {
  .gt-xs {
    display: none;
  }
}

.ct-label {
  font-size: 0.8em!important;
}

.stripe {
  height: 16px;
  background: url('../images/postal_decoration@1x.png')
}

.remove-icon {
  float: right;
  height: 23px;
  width: 14px;
  background: url('../images/Trash_bin.png') center no-repeat;
}

input, textarea {
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  background-color: #fffff7;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 2px 16px 0 rgba(117, 117, 117, 0.2);
  border-bottom: 1px solid rgba(206, 206, 204, 0.13);
  outline: rgba(103, 101, 101, 0.48);

  &:focus {
    box-shadow: 0 2px 16px 4px rgba(117, 117, 117, 0.2);

  }
}

footer {
  background: none;
  margin-top: 60px;
}

.nav-tabs {
  border-bottom: 1px solid rgba(17, 143, 221, 0.24);
}
.logo.navbar-brand > img {
  margin-top: 6px;
}
