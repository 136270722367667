.loading-spinner {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -15px;
  right: -15px;
  background-color: rgba(241, 241, 241, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
}
.loading-spinner.position-fixed {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.loading-spinner .fa-circle-o-notch {
  font-size: 48px;
  color: #2376bf;
}
