/* 
    Breadcrumbs styling
*/

.breadcrumb {
    list-style: none;
    padding: 0;
    background-color: transparent;
    border-radius: 0;

    > li {
        display: inline-block;

        + li:before {
            color: @breadcrumb-color;
        }
    }

    > .active {
        color: @breadcrumb-active-color;
    }
}
